export interface Breed {
  id: string
  name: string
  picture: string
  species: Species
}

export interface Species {
  id: string
  name: string
  picture: string
  breeds: Breed[]
}

export interface Animal {
  id: string
  picture: string | null
  species: string
  breed: string
  ain: string
  location: string
  mother: string
  father: string
  gender: string
  name: string
  birth_date: string
  entry_date: string
  entry_way: string
  description: string
  created_at: string
  updated_at: string
  ruminant_fields?: RuminantFields
  species_details?: Partial<Species>
  breed_details?: Partial<Breed>
  tasks: string[]
  notes: string[]
  medical_treatments_count?: number
  intended_purpose?: string
  is_healthy: boolean
  is_under_treatment: boolean
  statuses?: string[]
}

export type AnimalDraft = Omit<Animal, "id" | "created_at" | "updated_at">

export interface RuminantFields {
  insemination_count?: number
  gestation_count?: number
  calves_count?: number
  lactation_count?: number
  is_pregnant?: boolean
  is_lactating?: boolean
  is_in_heats?: boolean
}

export const initialRuminantFields: RuminantFields = {
  calves_count: 0,
  gestation_count: 0,
  lactation_count: 0,
  insemination_count: 0,
}

/**
 * This is related to the species API
 * The same values ar used to FARM_TYPE as well to reduce confusing situations
 * and ease the operations
 */
export enum ANIMAL_SPECIES_ENUM {
  COW = "Cow",
  GOAT = "Goat",
  SHEEP = "Sheep",
  PORK = "Pork",
  BIRD = "Bird",
  BEE = "Bee",
}

/**
 * This is used for translation purposes
 * Same values are to be found in more enums to ensure consistency
 * If you ask why I don't refer another Enum, its because it produces circular dependencies
 */
export enum ANIMAL_SPECIES_NAME_TRANSLATION_ENUM {
  COW = "Cow",
  VEAL = "Veal",
  CALF = "Calf",
  BEE = "Bee",
  CHICKEN = "Chicken",
  HEN = "Hen",
  COCK = "Cock",
  FEMALE_SHEEP = "FEMALE_SHEEP",
  RAM = "RAM",
  LAMB = "Lamb",
  PIGLET = "PIGLET",
  MALE_PIG = "MALE_PIG",
  FEMALE_PIG = "FEMALE_PIG",
  YOUNG_GOAT = "YOUNG_GOAT",
  MALE_GOAT = "MALE_GOAT",
  FEMALE_GOAT = "FEMALE_GOAT",
}
