import { type Config } from "tailwindcss"
import defaultTheme from "tailwindcss/defaultTheme.js"

export const extendedTheme = {
  colors: {
    brown: {
      50: "var(--color-brown-50)",
      100: "var(--color-brown-100)",
      200: "var(--color-brown-200)",
      300: "var(--color-brown-300)",
      400: "var(--color-brown-400)",
      500: "var(--color-brown-500)",
      600: "var(--color-brown-600)",
      700: "var(--color-brown-700)",
      800: "var(--color-brown-800)",
      900: "var(--color-brown-900)",
    },
    neutral: {
      50: "var(--color-neutral-50)",
      100: "var(--color-neutral-100)",
      200: "var(--color-neutral-200)",
      300: "var(--color-neutral-300)",
      400: "var(--color-neutral-400)",
      500: "var(--color-neutral-500)",
      600: "var(--color-neutral-600)",
      700: "var(--color-neutral-700)",
      800: "var(--color-neutral-800)",
      900: "var(--color-neutral-900)",
    },
    red: {
      50: "var(--color-red-50)",
      100: "var(--color-red-100)",
      200: "var(--color-red-200)",
      300: "var(--color-red-300)",
      400: "var(--color-red-400)",
      500: "var(--color-red-500)",
      600: "var(--color-red-600)",
      700: "var(--color-red-700)",
      800: "var(--color-red-800)",
      900: "var(--color-red-900)",
    },
    yellow: {
      50: "var(--color-yellow-50)",
      100: "var(--color-yellow-100)",
      200: "var(--color-yellow-200)",
      300: "var(--color-yellow-300)",
      400: "var(--color-yellow-400)",
      500: "var(--color-yellow-500)",
      600: "var(--color-yellow-600)",
      700: "var(--color-yellow-700)",
      800: "var(--color-yellow-800)",
      900: "var(--color-yellow-900)",
    },
    blue: {
      50: "var(--color-blue-50)",
      100: "var(--color-blue-100)",
      200: "var(--color-blue-200)",
      300: "var(--color-blue-300)",
      400: "var(--color-blue-400)",
      500: "var(--color-blue-500)",
      600: "var(--color-blue-600)",
      700: "var(--color-blue-700)",
      800: "var(--color-blue-800)",
      900: "var(--color-blue-900)",
    },
    "black-overlay": {
      40: "var(--color-black-overlay-40)",
      80: "var(--color-black-overlay-80)",
    },
    white: "var(--color-white)",
    border: "hsl(var(--border))",
    input: {
      DEFAULT: "hsl(var(--input))",
      invalid: "hsl(var(--input-invalid))",
    },
    ring: {
      DEFAULT: "hsl(var(--ring))",
      invalid: "hsl(var(--foreground-destructive))",
    },
    background: "hsl(var(--background))",
    foreground: {
      DEFAULT: "hsl(var(--foreground))",
      destructive: "hsl(var(--foreground-destructive))",
    },
    primary: {
      DEFAULT: "hsl(var(--primary))",
      foreground: "hsl(var(--primary-foreground))",
    },
    secondary: {
      DEFAULT: "hsl(var(--secondary))",
      foreground: "hsl(var(--secondary-foreground))",
    },
    destructive: {
      DEFAULT: "hsl(var(--destructive))",
      foreground: "hsl(var(--destructive-foreground))",
    },
    muted: {
      DEFAULT: "hsl(var(--muted))",
      foreground: "hsl(var(--muted-foreground))",
    },
    accent: {
      DEFAULT: "hsl(var(--accent))",
      foreground: "hsl(var(--accent-foreground))",
    },
    popover: {
      DEFAULT: "hsl(var(--popover))",
      foreground: "hsl(var(--popover-foreground))",
    },
    card: {
      DEFAULT: "hsl(var(--card))",
      foreground: "hsl(var(--card-foreground))",
    },
  },
  borderColor: {
    DEFAULT: "hsl(var(--border))",
  },
  borderRadius: {
    lg: "var(--radius)",
    md: "calc(var(--radius) - 2px)",
    sm: "calc(var(--radius) - 4px)",
  },

  fontSize: {
    // 1rem = 16px
    /** 80px size / 84px high / bold */
    mega: ["5rem", { lineHeight: "5.25rem", fontWeight: "700" }],
    /** 56px size / 62px high / bold */
    h1: [
      "3.5rem",
      {
        lineHeight: "3.875rem",
        fontWeight: "700",
      },
    ],
    /** 40px size / 48px high / bold */
    h2: ["2.5rem", { lineHeight: "3rem", fontWeight: "700" }],
    /** 32px size / 36px high / bold */
    h3: ["2rem", { lineHeight: "2.25rem", fontWeight: "700" }],
    /** 28px size / 36px high / bold */
    h4: ["1.75rem", { lineHeight: "2.25rem", fontWeight: "700" }],
    /** 24px size / 32px high / bold */
    h5: ["1.5rem", { lineHeight: "2rem", fontWeight: "700" }],
    /** 16px size / 20px high / bold */
    h6: ["1rem", { lineHeight: "1.25rem", fontWeight: "700" }],

    /** 32px size / 36px high / normal */
    "body-2xl": ["2rem", { lineHeight: "2.25rem" }],
    /** 28px size / 36px high / normal */
    "body-xl": ["1.75rem", { lineHeight: "2.25rem" }],
    /** 24px size / 32px high / normal */
    "body-lg": ["1.5rem", { lineHeight: "2rem" }],
    /** 20px size / 28px high / normal */
    "body-md": ["1.25rem", { lineHeight: "1.75rem" }],
    /** 16px size / 20px high / normal */
    "body-sm": ["1rem", { lineHeight: "1.25rem" }],
    /** 14px size / 18px high / normal */
    "body-xs": ["0.875rem", { lineHeight: "1.125rem" }],
    /** 12px size / 16px high / normal */
    "body-2xs": ["0.75rem", { lineHeight: "1rem" }],

    /** 18px size / 24px high / semibold */
    caption: ["1.125rem", { lineHeight: "1.5rem", fontWeight: "600" }],
    /** 12px size / 16px high / bold */
    button: ["0.75rem", { lineHeight: "1rem", fontWeight: "700" }],
  },
  fontFamily: {
    sans: ["Montserrat", ...defaultTheme.fontFamily.sans],
    poppins: ["var(--font-poppins)", ...defaultTheme.fontFamily.sans],
    poppinsBold: ["Poppins-Bold", ...defaultTheme.fontFamily.sans],
    poppinsLight: ["Poppins-Light", ...defaultTheme.fontFamily.sans],
    poppinsBlack: ["Poppins-Black", ...defaultTheme.fontFamily.sans],
    coHeadlineBold: ["Co Headline Bold", ...defaultTheme.fontFamily.sans],
    coHeadline: ["Co Headline Regular", ...defaultTheme.fontFamily.sans],
    coHeadlineLight: ["Co Headline Light", ...defaultTheme.fontFamily.sans],
  },
} satisfies Config["theme"]
